export default {
  heading: 'Cormorant Garamond, serif',
  subheading: 'Italiana',
  body: 'Open Sans, sans-serif',
  body2: 'Comfortaa, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa',
    'Cormorant Garamond:100,200,300,400,500,600,700,800,900',
    'Italiana'
  ],
  customFamilies: ['Amelie', 'Corinthian Light', 'Futura'],
  customUrls: [
    'https://www.gonation.biz/fonts/amelie/amelie.css',
    'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    'https://www.gonation.biz/fonts/futura/futura.css'
  ]
}
