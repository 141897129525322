export default {
  text: '#5b5b5b',
  text2: '#a11412',
  primary: '#a11412',
  secondary: '#5B564D',
  background: 'white',
  backgroundPrimary: '#b3e261',
  backgroundSecondary: '#603d34',
  backgroundTertiary: '#f1e7c2',
  light: '#FFF',
  dark: '#020303'
}
